import logo from '../img/logo_maps.png'

var map

window.initMap = function() {
  map = new google.maps.Map(document.getElementById('map'), {
    center: { lat: 50.1031, lng: 8.69117 },
    zoom: 16,
    mapTypeId: 'roadmap',
  })

  var marker = new google.maps.Marker({
    position: new google.maps.LatLng(50.1031, 8.69117),
    map: map,
    icon: logo,
    url: 'https://www.google.com/maps/search/?api=1&query=meichsner+und+dennerlein',
  })

  google.maps.event.addListener(marker, 'click', function() {
    window.open(this.url)
  })
}
